import React from 'react';
import img1 from '../images/payment-gateway.jpg'; 
import img2 from '../images/POS-Terminal.jpg';
import img3 from '../images/TMS .jpg';
import img4 from '../images/agency-banking.jpg';
import group from '../images/groupicon.png';
import tech from '../images/techicon.png';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-3" >
            <section >
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-logocolor uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-logocomplement'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-logocolor">Spend less time interacting with partner banks and more time on your customers.
                        </h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="100">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out    overflow-hidden text-gray-700  rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm justify-content-center">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-70 ease-in-out  mb-10" src={img1} />
                                    <h2 className="font-semibold my-4 text-logocolor text-2xl rounded text-center">Payment Gateway</h2>
                                    <p className="text-md font-medium">
                                    Our payment gateway enables you accept, process and manage payments using various payment methods - 
                                    such as credit cards, digital wallets, in a fast, secure and efficient manner.
                                    
 </p>
                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out    overflow-hidden text-gray-700  rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-70 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-logocolor text-center ">POS Terminals </h2>
                                    <p className="text-md font-medium">
                                    We offer top-notch Point-of-Sale (POS) Terminals are designed to ensure instant payment settlement and
                                    fast, secure, reliable transactions, which keep your customers happy and your business thriving.

                                    </p>
                                </div>
                            </div>  

                            <div className="bg-white transition-all ease-in-out    overflow-hidden text-gray-700  rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-70 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-logocolor text-center ">TMS</h2>
                                    <p className="text-md font-medium">
                                    TMS is a software that manages POS terminals. Our TMS offers payment monitoring and analytics. 
                                    It provides a report engine for agents and merchants to access transaction data from their terminals.


                                    </p>
                                </div>
                            </div> 

                            

                            <div className="bg-white transition-all ease-in-out    overflow-hidden text-gray-700  rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-70 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-logocolor text-center ">Agency Banking</h2>
                                    <p className="text-md font-medium">
                                    Our Agency banking platform enables registered agents to process financial transactions for customers, 
                                    making banking products more accessible.

                                    </p>
                                </div>
                            </div>

                                              
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 sm:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center bg-white p-5 rounded">
                            <div className=' flex text-logocolor '>
                                <img width="40" height="64"  src={group} alt="multicultural-people"/>
                                <h3 className="text-3xl  text-logocolor font-bold"><i class="fa fa-code" aria-hidden="true"></i>Built-in  <span className='font-black text-logocomplement' >Compliance Team</span></h3>

                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                               Reduce your workload, Leverage our top-notch team that handles fraud, AML, KYC, and other compliance-related tasks so you don't have to.
                                </p>
                            </div>
                        </div>
                       
                       
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center bg-white p-5 rounded">
                            <div className='flex text-logocolor '>
                            <img width="40" height="64"  src={tech} alt="multicultural-people"/>
                                <h3 className="text-3xl  text-logocolor 
                            font-bold">Robust and Reliable <span className='text-logocomplement'>Tech</span></h3>
                            </div>
                           
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Remove the anxiety of failed transactions, using our robust and modern technology, built on secure and reliable infrastructure that you can depend on.
                                </p>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;